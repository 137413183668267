import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Main",
    component: () => import(/* webpackChunkName: "about" */ "../views/Main.vue")
  },
  {
    path: "/Entrance",
    name: "Entrance",
    component: () => import(/* webpackChunkName: "about" */ "../views/Entrance.vue")
  },
  {
    path: "/CountingMoney",
    name: "CountingMoney",
    query: {
      hallCode: ""
    },
    component: () => import(/* webpackChunkName: "about" */ "../views/game/CountingMoney.vue")
  },
  {
    path: "/MonkeyClimbingTrees",
    name: "MonkeyClimbingTrees",
    query: {
      hallCode: ""
    },
    component: () => import(/* webpackChunkName: "about" */ "../views/game/MonkeyClimbingTrees.vue")
  },
  {
    path: "/CarRace",
    name: "CarRace",
    query: {
      hallCode: ""
    },
    component: () => import(/* webpackChunkName: "about" */ "../views/game/CarRace.vue")
  },
  {
    path: "/TcPlayer",
    name: "TcPlayer",
    query: {
      hallCode: ""
    },
    component: () => import(/* webpackChunkName: "about" */ "../views/TcPlayer.vue")
  },
  {
    path: "/businessMain",
    name: "businessMain",
    query: {
      hallCode: ""
    },
    component: () => import(/* webpackChunkName: "about" */ "../views/business/Main.vue")
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
